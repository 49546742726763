.header {
    /* position: fixed; */
    /* top: 0; */
    display: flex;
    flex-direction: row;
    width: 100%;
    /* min-height: 7vh; */
    background-color: #000;
    overflow: hidden;
    margin-left: auto;
    margin-right: auto;
    font-family: Verdana;
    z-index: 1000;
    /* align-items: baseline; */
}
.headerMode {
    width: 20%;
    color: #f2f2f2;
    margin: 0;
    text-align: center;
    padding: 14px 16px;
    text-decoration: none;
    /* font-size: 17px; */
    justify-self: center;
    /* cursor: pointer; */
}

.headerMode > img {
    width: 100%;
    min-width: 140px;
    max-width: 210px;
    height: auto;
}

.headerItem {
    color: #f2f2f2;
    text-align: center;
    padding: 14px 16px;
    text-decoration: none;
    font-size: 17px;
    justify-self: center;
    cursor: pointer;
    align-self: center;
}
.container {
    margin: 7vh;
    margin-top: 10vh;
    display: flex;
}
